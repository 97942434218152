import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { customizerSelector, selectFactureAchat } from '@b2d/redux/RootStore'; // Adjust the import as per your project structure
import { AppDispatch } from '@europrocurement/flexy-components/redux/storeConfig/store';
import { FactureFormObject } from '@b2d/pages/Achats/components/forms/types';
import { useSynchroG3Facture } from '@b2d/pages/Achats/components/forms/functions/dataHooks';
import models from '@b2d/pages/Achats/models';
import { toISOString } from '@europrocurement/l2d-utils/function-utils';

export type UpdateHeaderProps = {
    invoice: { id?: number | string };
    newHeader: Pick<FactureFormObject, 'numero_facture' | 'date_facture'>;
    isAlreadySyncG3?: boolean;
};

/**
 * Custom hook to calculate and update invoice header.
 */
const useSectionHeader = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const { xIdSociete } = useSelector(customizerSelector);
    const synchroG3Facture = useSynchroG3Facture();

    /**
     * Updates the header on the server.
     * @param newHeader - The header to update.
     */
    const updateHeader = useCallback(
        async ({ invoice, newHeader, isAlreadySyncG3 = false }: UpdateHeaderProps) => {
            const invoiceRequest = () =>
                models.invoicePurchase.patch({
                    id: invoice.id?.toString() || '-1',
                    factureAchatFactureAchatWrite: {
                        numeroFacture: newHeader.numero_facture,
                        dateFacture: toISOString(newHeader.date_facture),
                    },
                    xIdSociete,
                });

            const syncRequest = () => synchroG3Facture(invoice.id?.toString() || '-1');

            const requestPromiseList: Array<typeof invoiceRequest> = [];

            requestPromiseList.push(invoiceRequest);

            try {
                await Promise.all(requestPromiseList.map((req) => req()));

                if (isAlreadySyncG3) {
                    syncRequest();
                }

                if (invoice.id) {
                    dispatch(selectFactureAchat({ id: invoice.id }));
                }

                enqueueSnackbar(`L'entête de la facture à été mis à jour avec succès`);
            } catch (error) {
                console.error('Error updating header fields:', error);

                enqueueSnackbar(
                    `Une erreur est survenue lors de la mise à jour de l'entête de la facture`,
                    {
                        variant: 'error',
                    },
                );
            }
        },
        [dispatch, enqueueSnackbar, synchroG3Facture, xIdSociete],
    );

    return {
        updateHeader,
    };
};

export default useSectionHeader;
