import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';

import { FactureAchatApiObject } from '@europrocurement/l2d-domain';
import { FaOptionIcon, invoiceIcon } from '@europrocurement/l2d-icons';
import { FlexyHeaderForm, FlexyInput } from '@europrocurement/flexy-components';
import {
    FlexyForm,
    FormObject,
    defaultGridProps,
    inputDefaultProps,
} from '@europrocurement/flexy-form';
import { SubmitHandler } from 'react-hook-form';
import { Grid } from '@mui/material';
import useLoadingStatus from '@b2d/hooks/useLoading';
import useSectionDataManager from '@b2d/hooks/useSectionDataManager';
import { headerStructureFields } from '../../formElements/fragments/headerSection';
import { ModeProps } from '../../formElements/types';
import { FactureFormObject, ToggableSection } from '../../types';
import HeaderStructure from '../../../fragments/HeaderStructure';
import SkeletonLoader from '../../../SkeletonLoader';

export type UpdateHeaderSectionFormProps = {
    invoice: FactureAchatApiObject;
} & ToggableSection;

const UpdateHeaderSectionForm: FunctionComponent<UpdateHeaderSectionFormProps> = function ({
    invoice,
    switchLockValues,
}) {
    const { getSwitchLockValues, stateSwitchLockValues } = switchLockValues;

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const headerMode = useMemo<ModeProps>(
        () => ({ type: 'update', lock: stateSwitchLockValues.header.value }),
        [stateSwitchLockValues.header.value],
    );

    const headerStructure = useMemo(
        () =>
            headerStructureFields({ mode: headerMode }).map((field) => ({
                ...field,
                lg: 12 / headerStructureFields({ mode: headerMode }).length,
                md: 12 / headerStructureFields({ mode: headerMode }).length,
            })),
        [headerMode],
    );

    if (!invoice.id) {
        throw new Error(
            "La facture n'as pas d'id, si vous voyez ce message, Sylvain vous autorise a demander a Kevin de faire du piano. ",
        );
    }

    const { header: headerSection } = useSectionDataManager();
    const { updateHeader } = headerSection();

    const handleSubmit = useCallback<SubmitHandler<FormObject>>(
        async (values: Pick<FactureFormObject, 'numero_facture' | 'date_facture'>) => {
            setIsLoading(true);

            updateHeader({
                invoice,
                newHeader: values,
                isAlreadySyncG3: invoice.syncG3,
            }).finally(() => {
                stateSwitchLockValues.header.set(true);

                setIsLoading(false);
            });
        },
        [invoice, stateSwitchLockValues.header, updateHeader],
    );

    const formObject = useMemo<Partial<FactureFormObject>>(() => {
        if (!invoice.numeroFacture || !invoice.dateFacture)
            throw new Error('Missing one of header values');

        return {
            numero_facture: invoice.numeroFacture,
            date_facture: new Date(invoice.dateFacture),
        };
    }, [invoice.dateFacture, invoice.numeroFacture]);

    const readyToDisplay = useMemo<boolean>(
        () => !!(!isLoading && invoice.id && invoice.dateFacture),
        [invoice.dateFacture, invoice.id, isLoading],
    );

    const { loading } = useLoadingStatus({ checkReady: () => readyToDisplay });

    return (
        <SkeletonLoader
            isLoading={loading}
            type="FormSection"
        >
            <>
                <FlexyHeaderForm
                    label={
                        <HeaderStructure
                            icon={
                                <FaOptionIcon
                                    {...invoiceIcon.props}
                                    size="sm"
                                />
                            }
                            title="En-tête"
                        />
                    }
                    switches={[
                        ...(!invoice?.syncCegid
                            ? [getSwitchLockValues({ sectionToBlock: 'header' })]
                            : []),
                    ]}
                    outlined
                />
                {stateSwitchLockValues.header.value ? (
                    <Grid
                        container
                        {...defaultGridProps}
                    >
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <FlexyInput
                                {...inputDefaultProps}
                                variant="outlined"
                                // variant="standard"
                                name="numero"
                                type="displayData"
                                inputlabel="Numéro de facture"
                                value={invoice.numeroFacture || 'N/A'}
                                readOnly
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                        >
                            <FlexyInput
                                {...inputDefaultProps}
                                variant="outlined"
                                // variant="standard"
                                name="numero"
                                type="displayData"
                                inputlabel="Date"
                                value={
                                    invoice.dateFacture
                                        ? new Date(invoice.dateFacture).toLocaleDateString()
                                        : 'N/A'
                                }
                                readOnly
                            />
                        </Grid>
                    </Grid>
                ) : (
                    <FlexyForm
                        onSubmit={handleSubmit}
                        formObject={formObject}
                        formStructure={headerStructure}
                    />
                )}
            </>
        </SkeletonLoader>
    );
};

export default UpdateHeaderSectionForm;
